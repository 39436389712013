import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Table,
} from "reactstrap";
import GetTokenApi from "../../pages/KeyCloak/GetTokenApi";
import ImageCrop from "../../pages/Map/ImageCrop";
import CheckPermission from "../../pages/Auth/CheckPermission";
import api from "../../api/api";
import { useDragScroll } from "../../hooks/useDragScroll";
import "./ModalObjectDetails.css";
import ModalConfirmation from "../ModalConfirmation/ModalConfirmation";

const ModalObjectDetails = ({
  offCanvas,
  openDetailsModal,
  pointDetailOffcanvas,
  setHideLeftMenu,
  setOffCanvas,
  setViewLightComponents,
  toggleOffCanvas,
  viewLightComponents,
  updateMapPoints
}) => {
  const [listDetections, setListDetections] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [lastPage, setLastPage] = useState(false);
  const [userPermission, setUserPermission] = useState(null);
  const [modal, setModal] = useState(false);
  const [totalElements, setTotalElements] = useState();

  const toggle = () => setModal(!modal);

  const [ref] = useDragScroll();

  useEffect(() => {
    if (!userPermission) {
      GetTokenApi().then((res) => {
        setUserPermission(res);
      });
    }
  }, [pageNumber, userPermission]);

  useEffect(() => {
    setListDetections([]);
    setPageNumber(0);
  }, [pointDetailOffcanvas, offCanvas]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api.get(
          `/detections-obb/detected-objects/${pointDetailOffcanvas.point.id}?page=${pageNumber}&size=10`
        );

        setTotalElements(data.totalElements);

        setLastPage(data.last);

        if (data.first) {
          setListDetections(data.content);
        } else {
          setListDetections((prev) => [...prev, ...data.content]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [pointDetailOffcanvas, offCanvas, pageNumber]);

  const loadMoreData = () => {
    setPageNumber(pageNumber + 1);
  };

  const deleteObject = async (type) => {
    try {
      await api.delete(
        `/detected-objects/${type}/${pointDetailOffcanvas.point.id}`
      );

      updateMapPoints(pointDetailOffcanvas.point.id, type);
    } catch (e) {
      console.log(e);
    } finally {
      setOffCanvas(false);
      toggle();
    }
  };

  const detectedObjectName = {
    "cable-spacers": "do Espaçador de cabo",
    poles: "do poste",
    trees: "da árvore",
  };

  useEffect(() => {
    if (pointDetailOffcanvas.viewComponents) {
      setViewLightComponents(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointDetailOffcanvas.viewComponents]);

  function closeModal() {
    setHideLeftMenu(true);
    setViewLightComponents(false);
    toggleOffCanvas();
  }

  return (
    <>
      <ModalConfirmation
        toggle={toggle}
        modal={modal}
        text={`Tem certeza que deseja excluir o objeto #${pointDetailOffcanvas.point.id}?`}
        confirmFunction={() => deleteObject(pointDetailOffcanvas.type)}
      />
      <Offcanvas
        id="modal-object-details"
        backdrop={false}
        direction="end"
        isOpen={offCanvas}
        toggle={toggleOffCanvas}
      >
        <OffcanvasHeader toggle={closeModal}>
          Detalhes {detectedObjectName[pointDetailOffcanvas.type]} #
          {pointDetailOffcanvas.point?.id}
        </OffcanvasHeader>
        <OffcanvasBody>
          <Table hover>
            <tbody>
              <tr>
                <th>Latitude:</th>
                <td>
                  {Math.floor(pointDetailOffcanvas.point.latitude * 100000) /
                    100000}
                </td>
              </tr>

              <tr>
                <th>Longitude:</th>
                <td>
                  {Math.floor(pointDetailOffcanvas.point.longitude * 100000) /
                    100000}
                </td>
              </tr>

              {pointDetailOffcanvas.point.streetName != null && (
                <tr>
                  <th>Nome da rua:</th>
                  <td>{pointDetailOffcanvas.point.streetName}</td>
                </tr>
              )}

              {pointDetailOffcanvas.point.height != null && (
                <tr>
                  <th>Altura:</th>
                  <td>{pointDetailOffcanvas.point.height.toFixed(2)} m</td>
                </tr>
              )}

              {pointDetailOffcanvas.point.inclination != null && (
                <tr>
                  <th>Inclinação:</th>
                  <td>{pointDetailOffcanvas.point.inclination.toFixed(2)}°</td>
                </tr>
              )}

              {pointDetailOffcanvas.point.streetWidth != null && (
                <tr>
                  <th>Largura da rua:</th>
                  <td>{pointDetailOffcanvas.point.streetWidth.toFixed(2)} m</td>
                </tr>
              )}

              {pointDetailOffcanvas.point.setBack != null && (
                <tr>
                  <th>Recuo da rua:</th>
                  <td>{pointDetailOffcanvas.point.setBack.toFixed(2)} m</td>
                </tr>
              )}

              {pointDetailOffcanvas.point.distanceToTheNextPole != null && (
                <tr>
                  <th>Distancia para o próximo poste:</th>
                  <td>
                    {pointDetailOffcanvas.point.distanceToTheNextPole.toFixed(
                      2
                    )}{" "}
                    m
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <Row style={{ marginBottom: "1em" }}>
            {CheckPermission(
              userPermission && userPermission.role ? userPermission.role : "",
              "EMPLOYEE"
            ) ? (
              <Col>
                {pointDetailOffcanvas.type === "poles" && (
                  <button
                    onClick={() => {
                      setHideLeftMenu(true);
                      setViewLightComponents(!viewLightComponents);
                    }}
                    className="btn btn-outline-secondary col-12"
                    type="button"
                  >
                    Visualizar componentes
                  </button>
                )}
              </Col>
            ) : (
              ""
            )}
          </Row>
          <div className="btn-text-container">
            <small>
              {listDetections.length}/{totalElements} detecções carregadas
            </small>

            <small
              className="btn btn-danger remove-btn"
              onClick={() => toggle()}
            >
              Remover
            </small>
          </div>

          <Row className="pt-0 le-0 ps-0">
            <div className="detections-container" ref={ref}>
              {listDetections?.map((imageCropItem, index) => (
                <div
                  key={imageCropItem.id}
                  className="item"
                  onClick={() => {
                    openDetailsModal(
                      imageCropItem.detectedObjectId,
                      listDetections,
                      index,
                      pointDetailOffcanvas.point
                    );
                  }}
                >
                  <Row>
                    <div
                      style={{ width: "66px", height: "100%", padding: "0" }}
                    >
                      <ImageCrop
                        height="654"
                        width="66"
                        imageSrc={imageCropItem.image.path_low}
                        detectionData={imageCropItem}
                      />
                    </div>
                  </Row>
                </div>
              ))}
            </div>
          </Row>
          <Row
            className="m-0 bg-white"
            style={{
              position: "absolute",
              bottom: "0px",
              left: "0px",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingBottom: "40px",
              zIndex: "500",
              width: "100%",
            }}
          >
            <Button
              outline
              disabled={lastPage}
              className={"btn btn-outline-secondary"}
              onClick={() => {
                loadMoreData();
              }}
            >
              Mostrar mais...
            </Button>
          </Row>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default ModalObjectDetails;
