import React, { useState } from "react";
import { Button, Card, CardBody } from "reactstrap";
import { FaEraser, FaSave, FaDrawPolygon, FaMousePointer } from "react-icons/fa";
import "./ModalDragMapPoints.css";

const ModalDragMapPoints = ({
  clearAll,
  saveUpdatedMarkers,
  startDrawingPolygon,
  offCanvas,
  singleSelection,
}) => {
  const [activeButton, setActiveButton] = useState("");
  const handleButtonClick = (action, buttonType) => {
    setActiveButton(buttonType);
    action();
  };
  return (
    <Card
      className="modal-drag-map-points"
      style={{
        right: offCanvas ? "500px" : "125px",
        top: "65px",
        position: "absolute",
        zIndex: "1000",
        display: "block",
        overflowX: "scroll",
        width: "80px",
      }}
    >
      <CardBody
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <Button
          color="primary"
          outline={activeButton === "polygon"}
          onClick={() => handleButtonClick(startDrawingPolygon, "polygon")}
        >
          <FaDrawPolygon />
        </Button>
        <Button
          color="primary"
          outline={activeButton === "singleSelection"}
          onClick={() => handleButtonClick(singleSelection, "singleSelection")}
        >
          <FaMousePointer />
        </Button>
        <Button color="primary" onClick={clearAll}>
          <FaEraser />
        </Button>
        <Button color="danger" onClick={saveUpdatedMarkers}>
          <FaSave />
        </Button>
      </CardBody>
    </Card>
  );
};

export default ModalDragMapPoints;
